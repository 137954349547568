.chat {
  padding: 135px 9px 0px;
}

.buttonbar {
  width:100%;
  max-width:600px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.buttonbar .button {
  width: calc(50% - 20px);
}

.buttonlist .button {
  margin-bottom: 20px;
  display: block;
  width: 100%;
}

.topbar {
  position:fixed;
  top:0;
  left:0;
  padding:40px 9px 0 9px;
  width:100%;
  z-index:20;
  background:linear-gradient(to top, rgba(0,216,197,0) 0%,  rgba(0,216,197,1) 20%)
}

.topbar time {
  font-weight:200;
  display:block;
  font-size:1.6em;
}
.topbar .bubble {
  text-align:right;
}


@media screen and (min-width:640px) {
  .topbar {
    left:50%;
    margin-left:-300px;
    max-width:600px;
  }
}
