@charset "utf-8";
html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: sans-serif;
}

@font-face {
  font-family: 'Lane';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lane.ttf');
}

body {
  margin: 0;
  padding: 0;
  background: #00d8c5;
  color: #fff;
  font: caption;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

.chattie {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.fadient {
  pointer-events: none;
  height: 100px;
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.22) 0%, rgba(0, 0, 0, 0) 100px) fixed no-repeat 0 0;
}

.pagecontainer {
  position: relative;
  margin: 0 auto;
  padding-bottom: 20px;
  max-width: 600px;
}

.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

.error {
  text-align: center;
  margin: 0;
  line-height: 2;
  color: #f00;
  font-weight: bold;
}

.Intro p {
  color: #000;
}

.Intro p .button {
  color: #fff;
}

hr {
  border: 0;
  border-bottom: 1px solid #000;
  width: 50vw;
  margin: 25px auto 0;
  background: 0;
  height: 0;
}

.button {
  position: relative;
  -webkit-user-select: none;
  clear: both;
  border: 2px solid #fff;
  height: 50px;
  line-height: 50px;
  width: 180px;
  margin: 0 auto;
  padding: 0;
  display: block;
  color: #fff;
  border-radius: 100px;
  font-weight: 500;
  font-size: 18px;
  background: none;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: sans-serif;
  transition: background .2s ease-in;
}

.button.pending {
  pointer-events: none;
  background: #FFF;
}

.button img {
  line-height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  vertical-align: middle;
  max-height: 20px;
  margin: 13px 15px 12px 0;
}

.button svg {
  vertical-align: text-bottom;
}

.button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

form {
  position: relative;
  margin: 0 9.5%;
}

form label {
  font-family: sans-serif;
  display: block;
  -webkit-user-select: none;
}

form input,
form select {
  font: sans-serif;
  width: 100%;
  background: transparent;
  border: 0;
  border-bottom: 3px solid #fff;
  height: 50px;
  line-height: 50px;
  padding: 0;
  margin-bottom: 25px;
  color: #000;
  font-size: 18px;
  outline: none;
  border-radius: 0;
}

form input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

form .selectgroup {
  position: relative;
}

form .selectgroup svg {
  position: absolute;
  pointer-events: none;
  right: 10px;
  top: 0;
}
