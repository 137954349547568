header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 10;
  pointer-events: none;
}

header nav {
  height: 73px;
  width: 113px;
  background: url(/static/media/curve.f52fdda2.svg) no-repeat 0 0;
  padding-top: 9px;
  padding-left: 9px;
}

header a.active,
header a + a {
  display: none;
}

header nav > * {
  pointer-events: all;
}

header a.active + a {
  display: block;
}

header nav a img {
  height: 40px;
}

.page-header {
  padding-top: 60px;
  padding-bottom: 30px;
  -webkit-user-select: none;
}

.page-header img {
  display: block;
  margin: 0 auto 10px;
  width: 65px;
}

.page-header h2 {
  font-weight: 300;
  font-family: Lane;
  font-size: 35px;
  text-align: center;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.login {
  position: relative;
  width: 100%;
}

.login .toggle {
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 116px;
  line-height: 50px;
  text-align: center;
  border:0;
  background:none;
}

.login .toggle svg {
  display: block;
  margin: 10px auto;
}

.login .toggle:active svg {
  stroke: #000;
}

.small-error {
  text-align:center;
  margin:0;
  color:#f00;
}

.chatbubble {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 11px;
}

.chatbubble > img {
  display: block;
  height: 33px;
  width: 33px;
}

.chatbubble .bubble {
  display: block;
  position: relative;
  background: #fff;
  border-radius: 16px;
  padding: 10px;
  color: #000;
  width: calc(100% - 52px);
}

.chatBubble .bubble::first-letter {
  text-transform: capitalize;
}

.chatbubble .bubble::before {
  content: " ";
  display: block;
  width: 24px;
  height: 17px;
  position: absolute;
  bottom: 0;
  left: -12px;
  background: url(/static/media/bubbles.eb1282f9.svg) no-repeat center/cover;
}

.chatbubble.user {
  opacity: 0.8;
  flex-direction: row-reverse;
}

.chatbubble.user .bubble {
  width: auto;
}

.chatbubble.user .bubble::before {
  right: -12px;
  left: auto;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.chatbubble .bubble img {
  float: right;
  max-width: 120px;
  margin: 0 0 10px 10px;
  border-radius: 10px;
}

.chat {
  padding: 135px 9px 0px;
}

.buttonbar {
  width:100%;
  max-width:600px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.buttonbar .button {
  width: calc(50% - 20px);
}

.buttonlist .button {
  margin-bottom: 20px;
  display: block;
  width: 100%;
}

.topbar {
  position:fixed;
  top:0;
  left:0;
  padding:40px 9px 0 9px;
  width:100%;
  z-index:20;
  background:linear-gradient(to top, rgba(0,216,197,0) 0%,  rgba(0,216,197,1) 20%)
}

.topbar time {
  font-weight:200;
  display:block;
  font-size:1.6em;
}
.topbar .bubble {
  text-align:right;
}


@media screen and (min-width:640px) {
  .topbar {
    left:50%;
    margin-left:-300px;
    max-width:600px;
  }
}

.iframecontainer {
  position: relative;
  width:100%;
  height:100%;
  padding:10px;
}

iframe {
  border:0;
  width: 1px;
  min-width: 100%;
  *width: 100%;
  height:100%;
  max-height:100%;
  border-radius:10px;
}

.iframeRoundedCornerWrap {
 border-radius: 10px; 
 width: 100%; 
 overflow: hidden;
 height: calc(100% - 80px);
}

.page.Spreekuur .button {
  margin-top: 16px;
}
.button.logout-link {
  background: #fff;
  color: #f00;
  border-color: currentColor;
  border-width: 1px;
}

.Settings {
  text-align: center;
  padding: 0 20px;
}

.Settings a {
  color: inherit;
}

.settings-menu a {
  position: relative;
  display: block;
  width: 100%;
  height: 70px;
  line-height: 70px;
  text-decoration: none;
  border-radius: 100px;
  border: 1px solid #fff;
  margin-bottom: 20px;
}

.settings-menu a img {
  width: 32px;
  position: absolute;
  right: 20px;
  top: 19px;
}

.Settings p {
  margin: 0;
  padding-top: 25px;
}

.settingslist {
  margin: 0 9.5% 20px;
  max-height: calc(100vh - 300px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.settingsitem {
  position: relative;
  display: block;
  border-bottom: 3px solid #fff;
  margin: 0 0 9px;
  color: #000;
}

.settingsitem a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.settingsitem b {
  display: block;
  font-size: 20px;
  font-weight: 400;
}

.settingsitem span {
  display: block;
  padding: 9px 0;
  font-size: 16px;
}

.settingsitem .delete-item {
  position: absolute;
  top: 12px;
  right: 5px;
  text-align: center;
  width: 23px;
  height: 23px;
  border-radius: 23px;
  line-height: 23px;
  background: rgba(0, 0, 0, 0.3);
  border:0;
  margin:0;
  padding:0;
}

.settingsitem .delete-item svg {
  display: block;
  margin: 7px;
  stroke-width: 20%;
}

.next {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.next .button {
  width: auto;
  padding: 0 10px 0 20px;
}

.next .button svg {
  vertical-align: text-bottom;
}

@charset "utf-8";
html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: sans-serif;
}

@font-face {
  font-family: 'Lane';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/lane.97d2f1c4.ttf);
}

body {
  margin: 0;
  padding: 0;
  background: #00d8c5;
  color: #fff;
  font: caption;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

.chattie {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.fadient {
  pointer-events: none;
  height: 100px;
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.22) 0%, rgba(0, 0, 0, 0) 100px) fixed no-repeat 0 0;
}

.pagecontainer {
  position: relative;
  margin: 0 auto;
  padding-bottom: 20px;
  max-width: 600px;
}

.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: 100%;
  min-height: 100vh;
  min-height: calc(1vh * 100);
  min-height: calc(var(--vh, 1vh) * 100);
}

.error {
  text-align: center;
  margin: 0;
  line-height: 2;
  color: #f00;
  font-weight: bold;
}

.Intro p {
  color: #000;
}

.Intro p .button {
  color: #fff;
}

hr {
  border: 0;
  border-bottom: 1px solid #000;
  width: 50vw;
  margin: 25px auto 0;
  background: 0;
  height: 0;
}

.button {
  position: relative;
  -webkit-user-select: none;
  clear: both;
  border: 2px solid #fff;
  height: 50px;
  line-height: 50px;
  width: 180px;
  margin: 0 auto;
  padding: 0;
  display: block;
  color: #fff;
  border-radius: 100px;
  font-weight: 500;
  font-size: 18px;
  background: none;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: sans-serif;
  transition: background .2s ease-in;
}

.button.pending {
  pointer-events: none;
  background: #FFF;
}

.button img {
  line-height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  vertical-align: middle;
  max-height: 20px;
  margin: 13px 15px 12px 0;
}

.button svg {
  vertical-align: text-bottom;
}

.button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

form {
  position: relative;
  margin: 0 9.5%;
}

form label {
  font-family: sans-serif;
  display: block;
  -webkit-user-select: none;
}

form input,
form select {
  font: sans-serif;
  width: 100%;
  background: transparent;
  border: 0;
  border-bottom: 3px solid #fff;
  height: 50px;
  line-height: 50px;
  padding: 0;
  margin-bottom: 25px;
  color: #000;
  font-size: 18px;
  outline: none;
  border-radius: 0;
}

form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

form input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

form input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

form .selectgroup {
  position: relative;
}

form .selectgroup svg {
  position: absolute;
  pointer-events: none;
  right: 10px;
  top: 0;
}

