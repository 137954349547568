.button.logout-link {
  background: #fff;
  color: #f00;
  border-color: currentColor;
  border-width: 1px;
}

.Settings {
  text-align: center;
  padding: 0 20px;
}

.Settings a {
  color: inherit;
}

.settings-menu a {
  position: relative;
  display: block;
  width: 100%;
  height: 70px;
  line-height: 70px;
  text-decoration: none;
  border-radius: 100px;
  border: 1px solid #fff;
  margin-bottom: 20px;
}

.settings-menu a img {
  width: 32px;
  position: absolute;
  right: 20px;
  top: 19px;
}

.Settings p {
  margin: 0;
  padding-top: 25px;
}

.settingslist {
  margin: 0 9.5% 20px;
  max-height: calc(100vh - 300px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.settingsitem {
  position: relative;
  display: block;
  border-bottom: 3px solid #fff;
  margin: 0 0 9px;
  color: #000;
}

.settingsitem a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.settingsitem b {
  display: block;
  font-size: 20px;
  font-weight: 400;
}

.settingsitem span {
  display: block;
  padding: 9px 0;
  font-size: 16px;
}

.settingsitem .delete-item {
  position: absolute;
  top: 12px;
  right: 5px;
  text-align: center;
  width: 23px;
  height: 23px;
  border-radius: 23px;
  line-height: 23px;
  background: rgba(0, 0, 0, 0.3);
  border:0;
  margin:0;
  padding:0;
}

.settingsitem .delete-item svg {
  display: block;
  margin: 7px;
  stroke-width: 20%;
}

.next {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.next .button {
  width: auto;
  padding: 0 10px 0 20px;
}

.next .button svg {
  vertical-align: text-bottom;
}
