.login {
  position: relative;
  width: 100%;
}

.login .toggle {
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 116px;
  line-height: 50px;
  text-align: center;
  border:0;
  background:none;
}

.login .toggle svg {
  display: block;
  margin: 10px auto;
}

.login .toggle:active svg {
  stroke: #000;
}

.small-error {
  text-align:center;
  margin:0;
  color:#f00;
}
