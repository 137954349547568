.chatbubble {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 11px;
}

.chatbubble > img {
  display: block;
  height: 33px;
  width: 33px;
}

.chatbubble .bubble {
  display: block;
  position: relative;
  background: #fff;
  border-radius: 16px;
  padding: 10px;
  color: #000;
  width: calc(100% - 52px);
}

.chatBubble .bubble::first-letter {
  text-transform: capitalize;
}

.chatbubble .bubble::before {
  content: " ";
  display: block;
  width: 24px;
  height: 17px;
  position: absolute;
  bottom: 0;
  left: -12px;
  background: url(../img/bubbles.svg) no-repeat center/cover;
}

.chatbubble.user {
  opacity: 0.8;
  flex-direction: row-reverse;
}

.chatbubble.user .bubble {
  width: auto;
}

.chatbubble.user .bubble::before {
  right: -12px;
  left: auto;
  transform: scaleX(-1);
}

.chatbubble .bubble img {
  float: right;
  max-width: 120px;
  margin: 0 0 10px 10px;
  border-radius: 10px;
}
