.page-header {
  padding-top: 60px;
  padding-bottom: 30px;
  -webkit-user-select: none;
}

.page-header img {
  display: block;
  margin: 0 auto 10px;
  width: 65px;
}

.page-header h2 {
  font-weight: 300;
  font-family: Lane;
  font-size: 35px;
  text-align: center;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
