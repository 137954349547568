header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 10;
  pointer-events: none;
}

header nav {
  height: 73px;
  width: 113px;
  background: url(../img/curve.svg) no-repeat 0 0;
  padding-top: 9px;
  padding-left: 9px;
}

header a.active,
header a + a {
  display: none;
}

header nav > * {
  pointer-events: all;
}

header a.active + a {
  display: block;
}

header nav a img {
  height: 40px;
}
