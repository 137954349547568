.iframecontainer {
  position: relative;
  width:100%;
  height:100%;
  padding:10px;
}

iframe {
  border:0;
  width: 1px;
  min-width: 100%;
  *width: 100%;
  height:100%;
  max-height:100%;
  border-radius:10px;
}

.iframeRoundedCornerWrap {
 border-radius: 10px; 
 width: 100%; 
 overflow: hidden;
 height: calc(100% - 80px);
}

.page.Spreekuur .button {
  margin-top: 16px;
}